import React from 'react';
import Header2 from './header2';
import Footer from './footer';
import './home.css';
import Reviews from '../component/Reviews';
import reviews from '../data/data.json';
import Gmap from '../component/gmap';
// type rfce for a template

import Eval from '../images/adjustment.jpg';

import Appointment from '../images/appointment.jpg';

import Logo from '../images/covington_logo.png';

import { AiFillPhone,AiFillMail } from "react-icons/ai";
import { BsChatLeftQuoteFill } from "react-icons/bs";

import Dropdown from '@mui/joy/Dropdown';
import Menu from '@mui/joy/Menu';
import MenuButton from '@mui/joy/MenuButton';
import MenuItem from '@mui/joy/MenuItem';

import Drawer from '../component/Drawer';

function home() {
  return (
    <>
      <div className='home-body'>
        
        <div className='header-text'>
        <div className='background2'>
          <div className='contact-top' data-aos="fade-down">
              <div className='contact-top2'>
                  <a href="/contact-us"><button id='request-appointment-button'>Request Appointment</button></a>
                  <Dropdown>
                  <MenuButton id='office-hours-button'>Office Hours</MenuButton>
                  <Menu>
                      <div className='footer-hours-dropdown' data-aos="fade-in">
                              <div className='footer-hours-time'>
                                  <div>
                                      <h5 id='footer-day'></h5>
                                  </div>
                                  <div>
                                      <h5 id='footer-day2'>Morning</h5>
                                  </div>
                                  <div>
                                      <h5>Afternoon</h5>
                                  </div>
                                  
                              </div>
                              <div className='footer-hours-time'>
                                  <div>
                                      <h5 id='footer-day'>Monday:</h5>
                                  </div>
                                  <div>
                                      <h5 id='footer-day2'>9:00 - 1:00</h5>
                                  </div>
                                  <div>
                                      <h5>2:30 - 6:00</h5>
                                  </div>
                              </div>
                              <div className='footer-hours-time'>
                                  <div>
                                      <h5 id='footer-day'>Tuesday:</h5>
                                  </div>
                                  <div>
                                      <h5 id='footer-day2'>9:00 - 1:00</h5>
                                  </div>
                                  <div>
                                      <h5>2:30 - 6:00</h5>
                                  </div>
                              </div>
                              <div className='footer-hours-time'>
                                  <div>
                                      <h5 id='footer-day'>Wednesday:</h5>
                                  </div>
                                  <div>
                                      <h5 id='footer-day2'>9:00 - 1:00</h5>
                                  </div>
                                  <div>
                                      <h5>2:30 - 6:00</h5>
                                  </div>
                              </div>
                              <div className='footer-hours-time'>
                                  <div>
                                      <h5 id='footer-day'>Thursday:</h5>
                                  </div>
                                  <div>
                                      <h5 id='footer-day2'>9:00 - 1:00</h5>
                                  </div>
                                  <div>
                                      <h5>2:30 - 6:00</h5>
                                  </div>
                              </div>
                              <div className='footer-hours-time'>
                                  <div>
                                      <h5 id='footer-day'>Friday:</h5>
                                  </div>
                                  <div>
                                      <h5 id='footer-day2'>9:00 - 12:00</h5>
                                  </div>
                                  <div>
                                      <h5>12:00 - 2:00</h5>
                                  </div>
                              </div>
                      </div>
                  </Menu>
                  </Dropdown>
              </div>
              <div className='header-phone'>
                  <AiFillPhone id='phone'/>
                  <a href='tel:2536309777'><h3>(253) 630-9777</h3></a>
              </div>
          </div>
        </div>
        <hr />
        <div className='header' data-aos="fade-in">
          <a href='/'>
            <div className='logo'>
                <img id='logo_img' src={Logo} alt="spine" />
                <h1 id='logo'>Covington Chiropractic</h1>
            </div>
          </a>
          <div className='links'>
            
                <a href='/about-us' id='links'>About Us</a>
            
            
                <a href='/Dr_Amos_Chon' id='links'>Meet Dr. Amos</a>
            
            
                <a href='/our-services' id='links'>Our Services</a>
            
            
                <a href='/new-patients' id='links'>New Patients</a>
            
            {/* <div className='contact-us-button'> */}
                <a href='/contact-us' id='contact-us-button'>Contact Us</a>
            {/* </div> */}
          </div>
          <div className='menu-button2'>
            <Drawer/>
            {/* <Hamburger toggled={isOpen} toggle={openDrawer} /> */}

        </div>
        </div>
        
          <div className='header-text-h1' data-aos="fade-in">
            <h1 id='header-text-h1'>Aligning Your Health: Discover Pain Relief and Wellness Through Expert Chiropractic Care</h1>
            <a href="/contact-us"><button>Find Relief Today</button></a>
          </div>
          
          <div class="custom-shape-divider-bottom-1722971431">
              <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                  <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" class="shape-fill"></path>
              </svg>
          </div>
        </div>
        {/* <div className='hero'>
        <img src={Hero} alt="Physical therapist adjusting client's back" id='hero'/>
      </div> */}
      <div className='spacer'>
          <div className='body-section2-container'>
            <div className="body-section2">
              <h1 id='body-section2-h1' data-aos="fade-in">Find Care That's Focused on You</h1>
              <h3 data-aos="fade-in">We are not a one-size-fits-all practice and reject the notion that what works for one person is suitable for everyone. Dr. Chon is dedicated to developing personalized care plans for each patient, recognizing that your health needs are unique.</h3>
              <a href="/our-services" data-aos="fade-in"><button>Our Services</button></a>
            </div>
          </div>
        </div>
        <div className='body-section'>
          <div className='body-section-paragraph'>
            <h1 data-aos="fade-in">Covington Chiropractic</h1>
            <h1 id='subheader' data-aos="fade-in">Will Straighten You Out</h1>
            <h3 data-aos="fade-in">Discover relief from pain and reclaim the joy of activity with personalized chiropractic care at Covington Chiropractic. Under the leadership of Dr. Amos Young Won Chon, our team is dedicated to offering specialized care and tailored advice to enhance your life at home, at work, and in sports or other activities.</h3>
            <h3 data-aos="fade-in">Our primary goal is to deliver effective care techniques that align with your health objectives, helping you achieve optimal well-being. We combine in-office therapy sessions at our Covington location with customized exercise recommendations for you to follow at home. The outcome is a revitalized and thriving life.</h3>
            <a data-aos="fade-in" href="/about-us"><button>About Us</button></a>
          </div>
        </div>
        <div className='body-section3-container'>
          <div className="body-section3">
            <h1 data-aos="fade-in">Recover Quickly and Completely with Knowledge and Care</h1>
            <h3 data-aos="fade-in">At Covington Chiropractic, our goal is to help you recover as quickly as possible. We believe that education is essential for healing, along with lifestyle adjustments, exercises, and regular chiropractic treatments. We collaborate with you to develop personalized therapy plans and encourage active movement and participation to alleviate pain and improve mobility.</h3>
            <a href="/new-patients" data-aos="fade-in"><button>New Patients</button></a>
          </div>
          <div className='eval'>
            <img id='eval' src={Eval} alt="" />
          </div>
        </div>
        <hr />
        {/* <div className='body-picture'>
          <div className='body-picture-paragraph'>
            <h1>Recover Fast and Fully With Education and Care</h1>
            <h3>At Covington Chiropractic, we strive for the quickest possible recovery. Education is a key part of healing, as well as lifestyle changes, exercises, and, of course, adjustments at our office. We work with you to create custom therapy plans and encourage you to engage in movement and activity as we reduce your pain and immobility.</h3>
            <a href="/new-patients"><button>New Patients</button></a>
          </div>
        </div> */}
        
        
        
        <div>
          <div className='reviews'>
          {/* <h1>Our Testimonials</h1> */}
          
          <div className='reviews2'>
            <div className='reviews-2' data-aos="fade-in">
                {/* <div className='review-picture-container'>
                  <img src={Appointment} id='talk' alt="" />
                </div> */}
                <h1>Read what our clients love about us</h1>
                <h3>Hear from Our Satisfied Clients: Real Stories of Relief, Recovery, and Renewed Health Through Our Expert Chiropractic Care</h3>
                <a target="_blank" rel="noopener noreferrer" href="https://www.google.com/maps/place/Covington+Chiropractic/@47.3530869,-122.1250076,17z/data=!4m8!3m7!1s0x54905fbc456519ad:0x38b4ea4b97943e78!8m2!3d47.3530833!4d-122.1224327!9m1!1b1!16s%2Fg%2F1tdpfx4r?entry=ttu">
                <button id='more-reviews'>More Reviews</button></a>
            </div>
            <div className='reviews-1'>
              <div className='article-card' data-aos="fade-in">
                <div className='article-body'>
                  <div className='article-author-body'>
                    <div className='article-author'>
                      <BsChatLeftQuoteFill id='quote'/>
                      <h5>Ryan S.</h5>
                    </div>
                  </div>
                  <div>
                    <h5>My experience with Dr. Chon has been very pleasant. Super nice and helpful. He took the time to assess and evaluate my concerns and <strong>made me comfortable throughout the process</strong>. I would recommend Dr. Chon to my friends and family. Will make more appointments with him.</h5>
                  </div>
                </div>
              </div>
              <div className='article-card' data-aos="fade-in">
                <div className='article-body'>
                  <div className='article-author-body'>
                    <div className='article-author'>
                      <BsChatLeftQuoteFill id='quote'/>
                      <h5>Michelle L.</h5>
                    </div>
                  </div>
                  <div>
                    <h5>I have seen Dr. Chon a few times and I really enjoy my experience! He is very <strong>attentive and explains everything he does</strong>. I like how he makes sure that I understand my own body. I know chiropractors can be scary, but he is comforting. Good experience so far!</h5>
                  </div>
                </div>
              </div>
              <div className='article-card' data-aos="fade-in">
                <div className='article-body'>
                  <div className='article-author-body'>
                    <div className='article-author'>
                      <BsChatLeftQuoteFill id='quote'/>
                      <h5>Mamta T.</h5>
                    </div>
                  </div>
                  <div>
                    <h5>I have known Dr. Amos Chon for about 7 months now. I am suffering from chronic back pain. Amos has helped me and is still helping with my muscle stiffness/ imbalances. Amos is <strong>very patient, knowledgeable and caring</strong>. He cares for his patients and goes above and  beyond to help them feel better! I highly recommended Dr. Amos Chon.</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
          </div>
          
        </div>
      </div>
      <div className='make-appointment-container' data-aos="fade-in">
          <div className='footer-app-img'>
            <h1>Make An Appointment Today</h1>
            <a href="/contact-us"><button id='footer-app-img-button'>Schedule Now</button></a>
          </div>
          <div class="custom-shape-divider-bottom-1722894737">
              <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                  <path d="M600,112.77C268.63,112.77,0,65.52,0,7.23V120H1200V7.23C1200,65.52,931.37,112.77,600,112.77Z" class="shape-fill"></path>
              </svg>
          </div>
        </div>
      <>
    <div className='footer-body2'>
        <div className='footer-contact3'>
          <div className='footer-contact' data-aos="fade-in">
            <h1>Contact</h1>
            <h4>Phone: (253) 630-9777</h4>
            <h4>Fax: (253) 630-9806</h4>
            <h4>Text: (253) 499-7485</h4>
            <h4>27641 Covington Way SE, Suite 2</h4>
            <h4>Covington, WA 98042</h4>
          </div>
          <div className='services-footer' data-aos="fade-in">
            <h1>Services</h1>
            <a id='footer-a' href="/chiropractic-care">Chiropractic Care</a>
            <a id='footer-a' href="/sports-chiropractic">Sports Chiropractic</a>
            <a id='footer-a' href="/personal-injury">Personal Injury</a>
            <a id='footer-a' href="/klaser-treatment">K Laser Treatment</a>
            <a id='footer-a' href="/pediatric-chiropractic">Pediatric Chiropractic</a>
            <a id='footer-a' href="/pregnancy-chiropractic">Pregnancy Chiropractic</a>
            <a id='footer-a' href="/auto-accident-chiropractic">Auto Accident Chiropractic</a>
            <a id='footer-a' href="/senior-chiropractic">Senior Chiropractic</a>
          </div>
        </div>
        <div className='footer-map' data-aos="fade-in">
          <h1>Location</h1>
          <Gmap/>
        </div>
      </div></>
    </>
  )
}

export default home
import React from 'react';
import Header from '../landing_page/header';
import Footer from '../landing_page/footer';
import './Dr_Amos_Chon.css';
import Amos from '../images/Dr.-Amos-Young-Won-Chon.jpg';

export default function Meet_Our_Doctors() {
    return (
      <>
      <Header />
      
      <div className='dramoschon'>
      <div className='large-monitor'>
        <div className='image-wrap'>
          <img src={Amos} id='amos' alt="" />
          <div>
            {/* <h1>Meet Dr. Amos Young Won Chon</h1> */}
            <h3>Dr. Amos Young Won Chon, a second-generation Korean American, has devoted his life to holistic health. From an early age, he was drawn to healthcare, volunteering in hospitals, nursing homes, and shelters, as well as visiting shut-ins. His passion for helping others led him to succeed Dr. Neal Vitalis and now guide Covington Chiropractic.</h3>
          </div>
        </div>
        <div>
          <h1>A Mission Minded Leader</h1>
          <h3>Having lived in several states, including New Jersey, Montana, Tennessee, California, and now Washington, Dr. Chon is proud to call Covington home. He loves caring for patients in this vibrant community while also enjoying the natural beauty and outdoor activities of the area.</h3>
          <h3>Driven by a desire to serve, Dr. Chon once spent six months as a self-supported missionary in rural Bolivia. He has also traveled to China, Uganda, Kenya, and the Navajo Nation to provide chiropractic care to underserved communities.</h3>
        </div>
        <div>
          <h1>Personal Experience Inspiring Professional Excellence</h1>
          <h3>Dr. Chon’s chiropractic journey began after he was diagnosed with severe scoliosis as a child. Chiropractic care helped him grow an inch taller and, more importantly, inspired him to pursue a career in this field. This experience led him to earn his Doctor of Chiropractic degree from Palmer College of Chiropractic West, where he developed a passion for providing personalized care and promoting a healthy lifestyle.</h3>
          <button>Explore Our Services</button>
        </div>
        <div>
          <h1>Mentorship From the Best</h1>
          <h3>Under the mentorship of Dr. Nicholas Athens, former chiropractor for the San Francisco 49ers, Dr. Chon refined his skills in techniques such as Diversified, SOT, Activator, Thompson Drop, and cranial work. His expertise spans all ages, from infants to the elderly.</h3>
        </div>
        <div>
          <h1>Dr. Chon's Interests</h1>
          <h3>Beyond his chiropractic work, Dr. Chon is a classically trained cellist, avid photographer, and nature enthusiast. He has toured internationally as a principal cellist and soloist and continues to perform whenever he can.</h3>
        </div>
        <div>
          <h1>Start Your Healing Journey Today</h1>
          <h3>Benefit from Dr. Chon’s extensive knowledge and compassionate care. Schedule your appointment today!</h3>
          <a href="/contact-us"><button>Contact Us</button></a>
        </div>
      </div>
      </div>
      <Footer />
      </>
    )
  }
import { Route, Routes } from 'react-router-dom';
import Home from './landing_page/home';
import AboutUs from './pages/About_Us';
import MeetOurDoctors from './pages/Meet_Our_Doctors';
// import Chiropractic from './pages/Chiropractic';
import OurServices from './pages/Our_Services';
import ContactUs from './pages/Contact_Us';
import NewPatients from './pages/New_Patients';
import AutoAccidentChiropractic from './pages/Services/auto-accident-chiropractic';
import ChiropracticCare from './pages/Services/chiropractic-care';
import PediatricChiropractic from './pages/Services/pediatric-chiropractic';
import PersonalInjury from './pages/Services/personal-injury';
import PregnancyChiropractic from './pages/Services/pregnancy-chiropractic';
import SeniorChiropractic from './pages/Services/senior-chiropractic';
import SportsChiropractic from './pages/Services/sports-chiropractic';
import KlaserTreatment from './pages/Services/klaser_treatment';

import DrAmosChon from './pages/Dr_Amos_Chon';
import DrDarrenSiu from './pages/Dr_Darren_Siu';

import Confirmation from './pages/Confirmation';

// use this to install react router dom --> npm i react-router-dom --legacy-peer-deps

function App () {
    return (
        <Routes>
            <Route path="/" element={<Home />}/>
            <Route path="/about-us" element={<AboutUs />} />
            {/* <Route path="/meet-our-doctors" element={<MeetOurDoctors />}/> */}
            <Route path="/our-services" element={<OurServices />}/>
            <Route path="/new-patients" element={<NewPatients />}/>
            <Route path="/contact-us" element={<ContactUs />}/>
            <Route path="/auto-accident-chiropractic" element={<AutoAccidentChiropractic />}/>
            <Route path="/chiropractic-care" element={<ChiropracticCare />}/>
            <Route path="/pediatric-chiropractic" element={<PediatricChiropractic />}/>
            <Route path="/personal-injury" element={<PersonalInjury />}/>
            <Route path="/pregnancy-chiropractic" element={<PregnancyChiropractic />}/>
            <Route path="/senior-chiropractic" element={<SeniorChiropractic />}/>
            <Route path="/sports-chiropractic" element={<SportsChiropractic />}/>
            <Route path="/klaser-treatment" element={<KlaserTreatment />}/>
            <Route path="/Dr-Amos-Chon" element={<DrAmosChon />}/>
            {/* <Route path="/Dr_Darren_Siu" element={<DrDarrenSiu />}/> */}
            <Route path="/confirmation" element={<Confirmation/>}/>
        </Routes>
    );
}

export default App; 
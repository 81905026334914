import React from 'react'
import Header from '../landing_page/header';
import Footer from '../landing_page/footer';
import './New_Patients.css';
import Front from '../images/front-color.jpg';

export default function New_Patients() {
  return (
    <div>
      <Header/>
      <div className='new-patients-container'>
      <div className="large-monitor">
        <div data-aos="fade-in">
          <h1>New Patients at Covington Chiropractic</h1>
          <img id='services-images' src={Front} alt="" />

          <h3>At Covington Chiropractic, our goal from day one is to help you achieve and maintain optimal health. From the moment you walk through our doors, you’ll receive a warm welcome and a personalized approach tailored to your unique needs, ensuring care for as long as you need it.</h3>
          <h3>Dr. Chon is committed to helping you experience relief and healing as quickly as possible. While we never cut corners, we focus on providing immediate pain relief while laying the foundation for your long-term health and well-being.</h3>
          <div className='new-patient-buttons'>
            <a target="_blank" rel="noopener noreferrer" href="https://doc.vortala.com/childsites/uploads/1377/files/np-forms.pdf"><button>New Patient Forms</button></a>
            <button>Privacy Policy</button>
          </div>
        </div>
        <div data-aos="fade-in">
          <h1>Prepare for a Comprehensive First Visit and Quick Results</h1>
          <h3>During your first visit to our Covington office, you can expect a thorough examination, a discussion of your health priorities, and your initial round of therapy—all in about 30-45 minutes.</h3>
          <h3>Many patients notice immediate improvements after their first session. For some, a few visits may be necessary to address the underlying issues, while others may require around a dozen sessions for full recovery.</h3>
          <h3>Once you’re feeling healthy, Dr. Chon will shift the focus to maintenance care. This phase involves less frequent visits but plays a crucial role in preventing recurrences and future injuries.</h3>
        </div>
        <div data-aos="fade-in">
          <h1>Explore Our Flexible Payment Options</h1>
          <h3>We accept cash, credit cards, and checks for your convenience. Patients can also use Health Savings Accounts (HSA, FSA) and insurance PPOs. Please note that HMOs are out of network for us. We also no longer accept United Healthcare.</h3>
        </div>
        <div data-aos="fade-in">
          <h1>Get the Care You Need Today</h1>
          <h3>Contact us today to schedule an appointment and start your journey toward a healthier life!</h3>
          <a href="/contact-us"><button>Contact Us</button></a>
        </div>
      </div>
      </div>
      <Footer/>
    </div>
  )
}
